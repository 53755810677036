import { Patch, Post, Get, ServiceObject, Put } from './BaseService';
import LoginDTO from '@/dtos/LoginDTO';
import UserDTO from '@/dtos/UserDTO';
import UserDemoDTO from '@/dtos/UserDemoDTO';
import UserCreateDTO from '@/dtos/UserCreateDTO';
import ExaminadorDTO from '@/dtos/ExaminadorDTO';
import BiopassTemplateDTO from '@/dtos/BiopassTemplateDTO';
import UserResponse from "@/dtos/UserResponse";
import RegisterStatusEnum from '@/enums/RegisterStatusEnum';


const UsuarioService = {
    Login: (data: LoginDTO) => {
        return Post<LoginDTO, UserDTO>('api/Auth', data);
    },
    getUser: (id: string) => {
        return Get<UserDTO>(`api/User/${id}`);
    },
    getAllExaminers: () => {
        return Get<ExaminadorDTO[]>(`api/User/GetAllExaminers`);
    },

    getAllUsers: (limit: number, page: number): ServiceObject<UserResponse> => {
        return Get<UserResponse>(`api/User?pageSize=${limit}&page=${page}`);
    },
    enableDisableUser: async (id: string, registerStatus: RegisterStatusEnum) => {
        return Put<any, any>(`api/User/${id}/enableDisable`, { registerStatus: registerStatus })
    },
    getUserCpf: (cpf: string) => {
        return Get<UserDTO>(`api/User/cpf/${cpf}`);
    },
    getUserCpfEmail: (text: string) => {
        return Get<UserDTO[]>(`api/User/cpfEmail/${text}`);
    },
    PostDemoUser: (query: UserDemoDTO) => {
        return Post<any, UserDemoDTO>(`api/User`, query);
    },
    PostUser: (query: UserCreateDTO) => {
        return Post<any, UserCreateDTO>(`/api/User/admin`, query);
    },
    PostVerifyEmail: (data: string) => {
        return Post<any, any>(`api/User/verifyEmail/${data}`, {});
    },
    PostReset: (cpf: string) => {
        return Post<any, any>(`/api/User/reset/${cpf}`, {});
    },
    PatchUpdatePassword: (data: {
        cpf: string,
        password: string
    }) => {
        return Patch<any, any>(`/api/User/password`, data);
    },
    CreateBiopassUserFace: (data: BiopassTemplateDTO) => {
        return Post<any, BiopassTemplateDTO>(
            "api/BiopassID/Create",
            data
        );
    },
    VerifyBiometric: (data: BiopassTemplateDTO) => {
        return Post<BiopassTemplateDTO, boolean>(
            "api/BiopassID/Verify",
            data
        );
    }
};

export default UsuarioService;

